.homeSection, .homeSectionText, .aboutSection, .portfolioSection, .portfolioBody, .portfolioColumn, .contactSection, .contactBody form, .footerSection {
  display: flex;
  flex-direction: column; }

nav, .aboutBody, .portfolioRow {
  display: flex;
  flex-direction: row; }

.aboutSection, .experienceSection, .portfolioSection, .contactSection {
  padding-top: 2rem; }

.aboutHeaderText, .experienceHeaderText, .portfolioHeaderText, .contactHeaderText {
  font-size: 2.5em;
  font-family: 'Playfair Display', serif;
  margin-bottom: 1.25em;
  border-bottom: 3px solid #7dce94; }

#root {
  height: 100%; }

.app {
  height: 100%;
  font-family: 'Open Sans', sans-serif; }

nav {
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.5s;
  padding: 1.5rem;
  z-index: 10000; }

.navHeaderWrapper {
  display: flex;
  flex-direction: row; }
  .navHeaderWrapper div {
    visibility: hidden; }

.navTop {
  background-color: rgba(0, 0, 0, 0);
  font-size: 1.5rem; }
  .navTop .navHeaderTop {
    font-size: 0; }

.navBottom {
  background-color: white;
  font-size: 1.3rem; }

.navLink {
  margin: 0.9rem;
  padding: 0.1rem;
  z-index: 100; }
  .navLink:hover {
    cursor: pointer;
    border-bottom: 3px solid #7dce94; }

.particles-js {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0; }
  .particles-js canvas {
    z-index: 0; }

.homeSection {
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  background-color: #f6f5f3;
  padding-left: 3rem;
  z-index: 1; }

.homeSectionText {
  font-size: 5rem;
  font-family: 'Playfair Display', serif;
  z-index: 1; }

.aboutSection {
  align-items: center;
  padding-top: 2em;
  padding-bottom: 2em; }
  .aboutSection a {
    text-decoration: none;
    color: #53b370; }
    .aboutSection a:hover {
      color: #7dce94; }

.aboutBody {
  padding: 0 3rem;
  justify-content: center; }

.aboutText {
  padding: 0 3rem; }

.aboutPhoto img {
  width: 400px; }

.experienceSection {
  background-color: #f6f5f3; }
  .experienceSection a {
    text-decoration: none;
    color: #53b370; }
    .experienceSection a:hover {
      color: #7dce94; }

.experienceHeader {
  display: flex;
  justify-content: center; }

.vertical-timeline-element-icon {
  border-radius: 0 !important;
  background-color: #f6f5f3 !important; }
  .vertical-timeline-element-icon svg {
    width: 40px !important;
    height: 40px !important;
    margin-left: -20px !important;
    margin-top: -20px !important; }

.vertical-timeline-element-content {
  box-shadow: none !important; }

.vertical-timeline {
  margin-bottom: 0 !important;
  margin-top: 0 !important; }

.portfolioSection {
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem; }
  .portfolioSection a {
    text-decoration: none;
    color: #7dce94; }
    .portfolioSection a:hover {
      color: #53b370; }

.portfolioBody {
  width: 100%; }

.portfolioRow {
  justify-content: center;
  width: 100%;
  padding-bottom: 3rem; }

.portfolioColumn {
  align-items: center;
  background-color: #f6f5f3;
  width: 30vw;
  height: fit-content;
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.portfolioRowHeader {
  text-align: center;
  padding: 1.5rem 0; }
  .portfolioRowHeader svg {
    height: 20vh; }

.portfolioRowBody {
  padding: 0 1.5rem;
  padding-bottom: 1.5rem;
  margin: auto; }

.contactSection {
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  background-color: #f6f5f3; }

.contactBody {
  width: 30%; }
  .contactBody form {
    align-items: center; }

input[type='text'],
input[type='submit'],
textarea {
  width: 100%;
  margin: 0.7rem 0;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0; }
  input[type='text']:focus,
  input[type='submit']:focus,
  textarea:focus {
    outline: none;
    border-bottom: 1px solid #7dce94; }

textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 50px; }

input[type='submit'] {
  width: 50%;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #ecf0f1;
  outline: none;
  background-color: white; }
  input[type='submit']:focus {
    outline: none; }
  input[type='submit']:active {
    background-color: #ecf0f1; }

.footerSection {
  align-items: center;
  width: 100%;
  padding: 2rem 0; }

.footerSocialMedia {
  padding: 0.5rem;
  padding-bottom: 0.75rem; }
  .footerSocialMedia svg {
    width: 30px;
    height: 30px;
    margin: 0 0.5rem;
    fill: #3d3d3f;
    transition: 0.3s; }
    .footerSocialMedia svg:hover {
      fill: #7dce94; }

@media only screen and (max-width: 800px) {
  nav {
    flex-direction: column; }
  .navTop {
    background-color: white;
    font-size: 1.3rem; }
  .navHeaderWrapper {
    justify-content: space-between; }
    .navHeaderWrapper div {
      visibility: visible;
      cursor: pointer; }
  .navLinksWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 500px;
    opacity: 1;
    transition: 0.45s ease-out;
    overflow: visible; }
    .navLinksWrapper a {
      padding: 0.5rem 0; }
      .navLinksWrapper a span {
        background-color: #f6f5f3;
        padding: 0.2rem; }
  .hideNavLinksWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: 0.45s ease-out; }
    .hideNavLinksWrapper a {
      padding: 0.5rem 0; }
  .aboutBody {
    flex-direction: column;
    align-items: center; }
  .aboutPhoto img {
    width: 320px; }
  .aboutText {
    padding: 0; }
  .experienceSection {
    padding-left: 3rem;
    padding-right: 3rem; }
  .portfolioBody {
    padding: 0 2rem;
    padding-bottom: 3rem; }
  .portfolioRow {
    flex-direction: column;
    align-items: center;
    padding: 0; }
  .portfolioColumn {
    width: 100%;
    margin: 1.5rem 0; }
  .portfolioRowHeader svg {
    height: 13vh; } }

@media only screen and (max-width: 617px) {
  .aboutHeaderText, .experienceHeaderText, .portfolioHeaderText, .contactHeaderText {
    font-size: 2rem;
    margin-bottom: 1rem; }
  .homeSection {
    padding-left: 2.5rem; }
  .homeSectionText {
    font-size: 2.5rem; }
  .aboutBody {
    width: 100%;
    padding: 0 2.5rem; }
  .aboutText {
    padding: 0; }
  .portfolioSection {
    padding-left: 1rem;
    padding-right: 1rem; }
  .contactBody {
    width: 70%; } }
